/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"); */
body {
  font-family: "Inter", sans-serif;
  margin: 0px;
  padding: 0px;
  background-color: white !important;
}

option {
  color: black;
}
.outer-wrapper::-webkit-scrollbar-track {
  width: 4px;
  height: 4px;
}
.outer-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.outer-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  /* border-radius: 10px; */
}

/* Handle */
.outer-wrapper::-webkit-scrollbar-thumb {
  background: #76797b;

  /* border-radius: 10px; */
}

/* Handle on hover */
.outer-wrapper::-webkit-scrollbar-thumb:hover {
  background: rgb(7, 7, 7);
  width: 11px;
  height: 11px;
}
.swiper-pagination-bullet {
  background-color: #fff !important;
}
.swiper-pagination-bullet-active {
  background-color: #043057 !important;
}
.swiper-pagination {
  position: relative !important;
  margin-top: 20px !important;
}
.swiper-button-prev {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c2a5b;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  padding: 20px;
  border-radius: 50px;
}
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c2a5b;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  padding: 20px;
  border-radius: 50px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 20px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-feature-settings: "abvf";
  font-variant: initial;
  line-height: 1;
  color: white;
}
.sc-header--img {
  border-radius: 50%;
  align-self: center;
  padding: 10px;
  width: 70px;
  height: 70px;
}
.sc-message--content.sent .sc-message--text {
  color: white;
  background-color: #4e8cff;
  max-width: calc(100% - 120px) !important;
  word-wrap: break-word;
  border-radius: 10px;
  padding: 10px;
}
.sc-message--text {
  padding: 17px 20px;
  border-radius: 6px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  width: auto !important;
}
.sc-user-input {
  min-height: auto !important;
  margin: 0px;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: #f4f7f9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}
.sc-chat-window {
  width: 370px;
  height: 70vh !important;
  max-height: 590px;
  position: fixed;
  right: 25px;
  bottom: 100px;
  box-sizing: border-box;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.sc-user-input--send-icon-wrapper,
.sc-user-input--file-icon-wrapper {
  flex-direction: row;
  visibility: hidden !important;
}
.rbc-event-label {
  font-size: 12px !important;
  font-weight: 600;
}
@media screen and (max-width: 1536px) {
  .rbc-event-label {
    font-size: 10px !important;
    font-weight: 600;
  }
}
.rbc-today {
  background-color: white !important;
  color: #19baee;
}
.grecaptcha-badge { 
  visibility: hidden !important;
}
.efwna-d::after {
  content: "";
  position: absolute;
  height: 50%;
  width: 0px !important;
}
.efwna-d::before {
  content: "";
  border-top: 0px solid rgb(112, 112, 112)!important;
  position: absolute;
  top: -0.5px;
  width: 0px !important;
}
.efwna-d::after {
  border-top: 0px solid #8BC34A!important;
  top: calc(50% - 0.5px);
  width: 0px !important;
}
 .sc-ispOId:nth-child(2n+1):not(:last-child)::after {
  border-right: 0px solid rgb(112, 112, 112)!important;
}
 .sc-ispOId:nth-child(2n)::after {
  border-right: 0px solid rgb(112, 112, 112)!important;
}
 .sc-ispOId:nth-child(2n)::before {
  right: -1.5em;
  border: 0px!important;
}
 .sc-ispOId:nth-child(2n)::after {
  border-right: 1px solid rgb(112, 112, 112);
  border: 0px !important;
}
 .sc-ispOId:nth-child(2n+1):not(:last-child)::after {
  border-right: 1px solid rgb(112, 112, 112);
  border: 0px !important;
}