
.test .skeleton-item {
    background-color: #e2e2e2;
    background-repeat: repeat-y;
    position: relative;
    color: #e2e2e2;
    overflow: hidden;
    animation: shine 1s infinite;
  }
  
  .test .skeleton-item::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(45deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.2) 100%);
    /* background: linear(
      90deg,
    rgba(255,255,255, 1) 0,
    rgba(255,255,255, 0.8) 20%,
    rgba(255,255,255, 0.6) 60%,
    ); */
    animation: shimmer 2s infinite;
    content: '';
  }
  
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }